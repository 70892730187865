<template>
	<div
		v-if="type == 'terms'"
		class="wrap bg-white" style="background-color: white !important;"
	>
		<div class="header">
			<div class="top top_wh">
				<h2 class="tit_center">{{ $language.agreement.title }}</h2>
				<div class="util hide">
					<button class="arrow" @click="$emit('cancel')">
						<i class="icon icon-arrow-left"></i>
					</button>
				</div>
			</div>
		</div>
		<div class="section_wrap pt-50" style="height: 100%; overflow: auto !important;">
			<div class="container" >

				<iframe
					v-if="terms.stip_file_url"
					:src="terms.stip_file_url"
					style="width: 100%; height: 100vh; border: none"
				></iframe>
				<div
					v-else
					class="terms_wrap" v-html="terms.strip_contents"
				></div>
			</div>
		</div>
	</div>
	<div
		v-else-if="type == 'personal'"
		class="wrap bg-white" style="background-color: white !important;"
	>
		<div class="header">
			<div class="top top_wh">
				<h2 class="tit_center">{{$language.agreement.privacy_policy}}</h2>
				<div class="util hide">
					<button class="arrow" @click="$emit('cancel')">
						<i class="icon icon-arrow-left"></i>
					</button>
				</div>
			</div>
		</div>
		<div class="section_wrap pt-50">
			<div class="container ">

				<iframe
					v-if="personal.stip_file_url"
					:src="personal.stip_file_url"
					style="width: 100%; height: 100vh; border: none"
				></iframe>
				<div
					v-else
					class="terms_wrap" v-html="personal.strip_contents"
				></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'personal'
	, props: []
	, data: function(){
		return {
			program: {
				name: 'agree'
				, type: 'agree'
				, not_footer: true
				, not_header: true
			}
			, terms: ''
			, personal: ''
			, type: 'terms'
		}
	}
	, computed:{

	}
	, methods: {
		getData: async function(){
			try {

				const result = await this.$Request({
					method: 'POST'
					, url: this.$api_url.api_path.get_agreement
					, data: {

					}
					, type: true
				})
				console.log('result.success', result.success)
				if (result.success) {
					console.log('success')
					for(let i = 0; i < result.data.stip_list.length; i++){
						let t = result.data.stip_list[i]
						if(t.stip_code == 'CO00100002') {
							this.terms = t
							continue
						}
						if(t.stip_code == 'CO00100001') {
							this.personal = t
							continue
						}
					}
				} else {
					throw result.message
				}
			} catch (e) {
				console.log(e)
			}
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>
